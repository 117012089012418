import VerticalLine_VerticalNotchDashedLineComponent from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/skinComps/BaseVerticalLine/VerticalNotchDashedLine.skin';


const VerticalLine_VerticalNotchDashedLine = {
  component: VerticalLine_VerticalNotchDashedLineComponent
};


export const components = {
  ['VerticalLine_VerticalNotchDashedLine']: VerticalLine_VerticalNotchDashedLine
};

